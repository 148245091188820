<template>
    <v-card :disabled="!activeTransactCompleted">
      <v-card-title>
        <span class="headline">Add Transaction</span>
      </v-card-title>
      <v-progress-linear
        :active="!activeTransactCompleted"
        :indeterminate="!activeTransactCompleted"
        color="primary"
        />
      <v-card-text>
        <v-container>
            <v-row dense>
                <v-btn-toggle v-model="type" v-on:change="setDefaultAccount" mandatory>
                    <v-btn>Purchase</v-btn>
                    <v-btn>Income</v-btn>
                    <v-btn>Transfer</v-btn>
                </v-btn-toggle>
            </v-row>
            <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                    <v-combobox label="Transaction" v-model="formName" :items="transactNameList" item-text="name">
                    </v-combobox>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="formDate"
                                label="Date"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            >
                            </v-text-field>
                        </template>
                        <v-date-picker
                            v-model="formDate"
                            no-title
                            scrollable
                            @input="menu = false"
                            >
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                    <v-menu
                        ref="menu-post"
                        v-model="menuPost"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <!-- TODO: some way to show when utilized... a range w/ original date included? -->
                            <v-btn icon v-bind="attrs" v-on="on"><v-icon>mdi-arrow-top-right</v-icon></v-btn>
                        </template>
                        <v-date-picker
                            v-model="formDatePost"
                            no-title
                            scrollable
                            @input="menuPost = false"
                            >
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                    <v-textarea label='Description' rows="1" v-model="formDescription">
                    </v-textarea>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  v-show='type === 0 || type === 2'
                >
                    <v-select
                        label="From"
                        v-model="formFromAccount"
                        :items="accountList"
                        item-value="accountid"
                        item-text="shortname"
                        >
                    </v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  v-show='type === 1 || type === 2'
                >
                    <v-select
                        label="To"
                        v-model="formToAccount"
                        :items="accountList"
                        item-value="accountid"
                        item-text="shortname"
                        >
                    </v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                    <v-text-field label="Amount" v-on:change="setState()" v-model="formAmount">
                    </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                    <v-combobox label="Tags" v-model="formTags" :items="transactTags" item-text="tag.value" multiple small-chips>
                    </v-combobox>
                </v-col>
            </v-row>
            <h3>Budget</h3>
            <template
                v-for="(budget, index) in formBudgets"
            >
                <v-row :key="index">
                    <v-col
                        cols="3" sm="3" md="2"
                        v-show="type === 0 || type === 2"
                    >
                        <v-select
                            label="From"
                            v-model="budget.fromCategoryInstance"
                            :items="getCategoryInstanceList()"
                            item-text="budgetcategory.name"
                            item-value="budgetcategoryinstanceid"
                        >
                          <template v-slot:selection="{ item }">
                            <v-list-item-content>
                              <v-list-item-title v-text="item.budgetcategory.name" />
                              <v-list-item-subtitle v-text="item.instance.name" />
                            </v-list-item-content>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title v-text="item.budgetcategory.name" />
                              <v-list-item-subtitle v-text="item.instance.name" />
                            </v-list-item-content>
                          </template>
                        </v-select>
                    </v-col>
                    <v-col
                        cols="3" sm="3" md="2"
                        v-show="type === 1 || type === 2"
                    >
                        <v-select
                            label="To"
                            v-model="budget.toCategoryInstance"
                            :items="getCategoryInstanceList()"
                            item-text="budgetcategory.name"
                            item-value="budgetcategoryinstanceid"
                        >
                          <template v-slot:selection="{ item }">
                            <v-list-item-content>
                              <v-list-item-title v-text="item.budgetcategory.name" />
                              <v-list-item-subtitle v-text="item.instance.name" />
                            </v-list-item-content>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title v-text="item.budgetcategory.name" />
                              <v-list-item-subtitle v-text="item.instance.name" />
                            </v-list-item-content>
                          </template>
                        </v-select>
                    </v-col>
                    <v-col
                        cols="3" sm="3" md="2"
                    >
                        <v-text-field label="Amount" :disabled="getBudgetAmountDisabled()" v-on:change="setState()" v-model="budget.amount">
                        </v-text-field>
                    </v-col>
                    <v-col
                        cols="3" sm="3" md="2"
                    >
                        <v-textarea label="Description" rows="1" v-model="budget.description">
                        </v-textarea>
                    </v-col>
                    <v-col
                        cols="3" sm="3" md="2"
                    >
                        <v-combobox label="Tags"
                                    v-model="budget.tags" :items="budgetTransactTags"
                                    item-text="tag.value" item-value="tag.value"
                                    multiple small-chips
                        >
                        </v-combobox>
                    </v-col>
                    <v-col
                        cols="3" sm="3" md="2"
                    >
                        <v-btn icon @click="updateBudget(index)" :disabled="getScaleDisabled()"><v-icon>mdi-scale-balance</v-icon></v-btn>
                        <v-spacer />
                        <v-btn @click="removeBudget(index)" icon><v-icon>mdi-alpha-x-circle</v-icon></v-btn>
                    </v-col>
                </v-row>
            </template>
        </v-container>
      </v-card-text>
      <v-progress-linear
          :active="!activeTransactCompleted"
          :indeterminate="!activeTransactCompleted"
          color="primary"
      />
      <v-card-actions>
        <v-btn @click="resetForm()">Discard Changes</v-btn>
        <v-btn v-if="id" @click="deleteTransact()">Delete</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="addBudget()">Add Budget</v-btn>
        <v-btn @click="$emit('cancel')">Cancel</v-btn>
        <v-btn @click="$emit('save', generateOutput())" :disabled="getSaveDisabled()">Save</v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
import _ from "lodash"
import {Action, Getter} from '@/store/transact/types.js'
import {Action as AccountAction, Getter as AccountGetter} from '@/store/account/types.js'
import {Getter as BudgetGetter} from '@/store/budgetCategory/types.js'
import {Action as ProfileAction, Getter as ProfileGetter} from '@/store/profile/types.js'
import {Action as BudgetTransactAction, Getter as BudgetTransactGetter} from '@/store/budgetTransact/types'
import {Getter as InstanceGetter} from '@/store/instance/types'
import Vue from 'vue'

export default {
    name: 'TransactForm',
    props: {
        id: String,
        name: String,
        description: String,
        date: {
            type: String,
            default: new Date().toISOString().substr(0, 10),
        },
        datePost: {
            type: String,
            default: null,
        },
        amount: {
            type: Number,
            default: 0
        },
        tags: Array,
        toAccount: Object,
        fromAccount: Object,
        transactAttrLinkId: {
            type: String,
            default: null,
        },
        budgets: {
            type: Array,
            default: function() {
                return [{
                    amount: 0,
                    toCategoryInstance: null,
                    fromCategoryInstance: null,
                    description: null,
                    tags: null,
                }]
            }
        },
        newBudget: {
            type: Object,
            default: function() {
                return {
                    amount: 0,
                    budgetTransactLinkId: null,
                    toCategoryInstance: null,
                    fromCategoryInstance: null,
                    description: null,
                    tags: null,
                }
            }
        },
        // 0 = Purchase, 1 = Income, 2 = Transfer
        inputType: {
            type: Number,
            default: 0
        },
        instanceId: {
            type: Number,
          default: null,
        }
    },
    data: () => ({
        type: 0,
        menu: false,
        menuPost: false,

        // initialized by props and created()
        formName: null,
        formDescription: null,
        formDate: null,
        formDatePost: null,
        formAccount: null,
        formAmount: 0,
        formTags: [],
        formToAccount: null,
        formFromAccount: null,
        formBudgets: [],
    }),
    computed: {
      accountList() {
          return this.$store.getters[`${[AccountGetter.getAccountList]}`]
      },
      transactTags() {
          return this.$store.getters[`${[Getter.GetDistinctTransactTags]}`]
      },
      budgetTransactTags() {
          return this.$store.getters[`${[BudgetTransactGetter.GetDistinctBudgetTransactTags]}`]
      },
      transactNameList() {
          return this.$store.getters[`${[Getter.GetTransactNameList]}`]
      },
      positiveCategoryList() {
        return this.$store.getters[`${[Getter.GetPositiveCategoryInstanceList]}`]
      },
      negativeCategoryList() {
        return this.$store.getters[`${[Getter.GetNegativeCategoryInstanceList]}`]
      },
      savingCategoryList() {
        return this.$store.getters[`${[BudgetGetter.GetSavingCategories]}`]
      },
      negativeSavingCategoryList() {
        return this.negativeCategoryList.concat(this.savingCategoryList)
      },
      allCategoryList() {
        return this.$store.getters[`${[Getter.GetAllCategoryInstanceList]}`]
      },
      nonExternalCategoryList() {
        let data = this.$store.getters[`${[Getter.GetAllCategoryInstanceList]}`]
        data = data.filter(
            (elt) => {
              return elt.budgetcategory.budget.budgettypeid !== 3
            }
        )
        return data
      },
      userProfile() {
          return this.$store.getters[`${[ProfileGetter.getUserProfile]}`]
      },
      finpicProfile() {
          return this.$store.getters[`${[ProfileGetter.getFinpicProfile]}`]
      },
      activeInstance() {
          return this.$store.getters[`${[InstanceGetter.GetActiveInstance]}`]
      },
      activeFinpic() {
          return this.$store.getters[`${[ProfileGetter.getActiveFinpic]}`]
      },
      activeTransactCompleted() {
        return this.$store.getters[`${[Getter.GetActiveTransactCompleted]}`]
      },
    },
    watch: {
        accountList: function() {
            this.setDefaultAccount()
        },
        finpicProfile: function() {
            this.setDefaultAccount()
        },
        instanceId: function() {
            console.log('INSTANCE ID CHANGED: ' + this.instanceId)
          //this.$store.dispatch(`${[Action.PositiveCategoryInstanceList]}`, {instanceid: this.instanceId})
          this.$store.dispatch(`${[Action.CategoryInstanceList]}`, {instanceid: this.instanceId})
        }
    },
    created() {
      // TODO - fire this when "re-loaded" too?
      // TODO - make this asynchronous if it is an update?
      // TODO - a way to reduce setDefaultAccount redundancy
      this.$store.dispatch(`${[AccountAction.loadAccountList]}`, {}) //.then(function() {console.log('resolved1'); this.setDefaultAccount()});
      this.$store.dispatch(`${[Action.DistinctTransactTags]}`)
      this.$store.dispatch(`${[BudgetTransactAction.FetchDistinctBudgetTransactTags]}`)
      this.$store.dispatch(`${[Action.TransactNameList]}`)
      //this.$store.dispatch(`${[Action.PositiveCategoryInstanceList]}`, {instanceid: this.instanceId})
      console.log('Firing : ' + this.instanceId)
      this.$store.dispatch(`${[Action.CategoryInstanceList]}`, {instanceid: this.instanceId})
      this.$store.dispatch(`${ProfileAction.loadUserProfile}`)

      // initialize values from props
      this.resetForm()
    },
    methods: {
      resetForm: function() {
          this.type = this.inputType
          this.formName = this.name;
          this.formDescription = this.description;
          this.formDate = this.date
          this.formDatePost = this.datePost;
          this.formAmount = this.amount;
          this.formTags = this.tags
          this.formToAccount = this.toAccount;
          this.formFromAccount = this.fromAccount;

          // TODO: this does not seem to reset properly... maybe need a deep copy?
          this.formBudgets = _.cloneDeep(this.budgets);

          this.$nextTick(() => {
            this.setDefaultAccount()
          })
      },
        getCategoryInstanceList: function() {
            if (this.type === 0) {
              return this.negativeSavingCategoryList
            } else if (this.type === 1) {
              // income could really go anywhere... except external
              return this.nonExternalCategoryList
            } else {
              return this.allCategoryList
            }
        },
        addBudget: function() {
            console.log("Adding budget...")
            this.formBudgets.push(_.cloneDeep(this.newBudget))
            console.log(this.formBudgets)
            console.log(this.newBudget)
            this.setState()
        },
        removeBudget: function(index) {
            this.formBudgets.splice(index, 1)
            this.setState()
        },
        budgetSum: function() {
            return this.formBudgets.reduce((x, y) => x + Number(y.amount), 0)
        },
        budgetCount: function() {
            return this.formBudgets.length
        },
        diffAmount: function() {
            if (this.budgetCount() === 0) {
                return 0
            } else {
                // TODO: round to nearest cent
                return this.roundMoney(this.formAmount - this.budgetSum())
            }
        },
        roundMoney: function(input) {
            return Math.round(input * 100) / 100
        },
        getScaleDisabled: function() {
            return this.diffAmount() === 0
        },
        getScaleColor: function() {
            return "#000000"
        },
        getSaveDisabled: function() {
            return !this.getScaleDisabled()
        },
        getBudgetAmountDisabled: function() {
            return this.budgetCount() === 1
        },
        getBudgetsDefault: function() {
            return [_.cloneDeep(this.newBudget)]
        },
        setState: function() {
            if (this.budgetCount() === 1) {
                // if budgetCount is 1, we can keep amount in sync
                this.updateBudget(0)
            }
            this.setDefaultAccount()
        },
        externalAccountIsValid: function() {
            if (this.finpicProfile) {
                return typeof(this.finpicProfile.externalaccount) === 'number'
            } else {
                return false
            }
        },
        defaultAccountIsValid: function() {
            if (this.finpicProfile) {
                return typeof(this.finpicProfile.defaultaccount) === 'number'
            } else {
                return false
            }
        },
        accountIsExternal: function(id) {
            return this.externalAccountIsValid() && this.finpicProfile.externalaccount === id
        },
        externalBudgetCategoryInstanceIsValid: function() {
            if (this.finpicProfile) {
                return typeof(this.finpicProfile.externalbudgetcategoryinstance) === 'number'
            } else {
                return false
            }
        },
        setDefaultAccount: function() {
            if ( this.type === 0 ) {
                // purchase
                console.log('starting')
                console.log(this.externalAccountIsValid())
                if (this.externalAccountIsValid()) {
                    console.log('fix one')
                    this.formToAccount = this.finpicProfile.externalaccount
                }

                this.formBudgets = this.formBudgets.map(
                    (budget) => {
                      if (this.externalBudgetCategoryInstanceIsValid()) {
                        Vue.set(budget, 'toCategoryInstance', this.finpicProfile.externalbudgetcategoryinstance)

                        if (budget.fromCategoryInstance == this.finpicProfile.externalbudgetcategoryinstance) {
                          // ensure "from" is not set to "external"
                          Vue.set(budget, 'fromCategoryInstance', null)
                        }
                      }

                      return budget
                    }
                )

                // if undefined or external, set to default
                if (
                    (
                        typeof(this.formFromAccount) === 'undefined' ||
                        this.accountIsExternal(this.formFromAccount)
                    ) &&
                    this.defaultAccountIsValid()
                ) {
                  console.log('fix three')
                  Vue.set(this, 'formFromAccount', this.finpicProfile.defaultaccount)
                }
            } else if ( this.type === 1 ) {
                // income
                if (this.externalAccountIsValid()) {
                  Vue.set(this, 'formFromAccount', this.finpicProfile.externalaccount)
                }

                console.log("Setting budget defaults")
                console.log("Instance: " + this.finpicProfile.externalbudgetcategoryinstance)
                  this.formBudgets = this.formBudgets.map(
                      (budget) => {
                        if (this.externalBudgetCategoryInstanceIsValid()) {
                          Vue.set(budget, "fromCategoryInstance", this.finpicProfile.externalbudgetcategoryinstance)

                          if (budget.toCategoryInstance == this.finpicProfile.externalbudgetcategoryinstance) {
                            // ensure "TO" is not set to "External"
                            Vue.set(budget, "toCategoryInstance", null)
                          }
                        }

                        // TODO: get incomebudgetcategory (and thus, instance) from finpicprofile...?

                        return budget
                      }
                  )
                // if undefined or external, set to default
                console.log(this.formToAccount)
                if (
                    (
                        typeof(this.formToAccount) === 'undefined' ||
                        this.accountIsExternal(this.formToAccount)
                    ) &&
                    this.defaultAccountIsValid()
                ) {
                  console.log("Setting to account")
                  Vue.set(this, 'formToAccount', this.finpicProfile.defaultaccount)
                } else {
                  console.log("Not setting to Account")
                }
            } else {
                // transfer
                console.log('transfer - do nothing...')
            }
        },
        getComboBoxName: function(formData) {
            if (typeof(formData) === 'object' && formData) {
                return formData.name
            } else {
                return formData
            }
        },
        deleteTransact: function() {
          this.$emit("delete", this.id)
        },
        generateOutput: function() {
            let tagText = []
            if (this.formTags) {
                tagText = this.formTags.map(
                    (formData) => {
                        if (typeof(formData) === 'object' && formData) {
                            return formData.tag.value
                        } else {
                            return formData
                        }
                    })
            }
            const nameText = this.getComboBoxName(this.formName)
            return {
                id: this.id,
                name: nameText,
                description: this.formDescription,
                date: this.formDate,
                datePost: this.formDatePost,
                amount: this.formAmount,
                tags: tagText,
                transactAttrLinkId: this.transactAttrLinkId,
                toAccount: this.formToAccount,
                fromAccount: this.formFromAccount,
                budgets: this.formBudgets,
                finpicid: this.activeFinpic.finpicid,
            }
        },
        updateBudget: function(index) {
            let newAmount;
            if ((this.budgetCount() - 1) < index) {
                // ERROR? do nothing
                return
            } else if (this.diffAmount() === 0) {
                // no diff... do nothing
                return
            } else {
                newAmount = this.roundMoney(Number(this.formBudgets[index].amount) + this.diffAmount())
                if (newAmount < 0) {
                    newAmount = 0
                }
                this.formBudgets[index].amount = newAmount
                return
            }
        },
    },
}
</script>
